import { buildTestIds, useBlackFriday, useShallow } from "application";
import { useAppContext } from "application/context/state";
import { PageTemplateComponents } from "components";
import { crm } from "implementations";
import { memo, useEffect } from "react";
import { BlackFridayThemeDto, NewTemplateDto } from "typing";
import { HomeContainer } from "./styles";

const Home = memo(
  ({
    components,
    blackFridayTheme,
  }: NewTemplateDto & {
    blackFridayTheme: BlackFridayThemeDto;
  }) => {
    const { setIsBlackFridayTheme } = useBlackFriday(
      useShallow((state) => ({
        setIsBlackFridayTheme: state.setIsBlackFridayTheme,
      }))
    );

    useEffect(() => {
      setIsBlackFridayTheme(blackFridayTheme?.isBlackTheme);
    }, [blackFridayTheme?.isBlackTheme, setIsBlackFridayTheme]);

    crm.pageObject("Home");

    const {
      isClientMobile,
      isClientTablet,
      isClientLaptop,
      setPageThemeMap,
      withBlackTheme,
    } = useAppContext();

    useEffect(() => {
      setPageThemeMap((currentPageThemeMap) => ({
        ...currentPageThemeMap,
        home: blackFridayTheme?.isBlackTheme ? "black" : "default",
      }));
      withBlackTheme(blackFridayTheme?.isBlackTheme as boolean);
    }, [blackFridayTheme?.isBlackTheme, setPageThemeMap, withBlackTheme]);

    const pageTemplateComponents = PageTemplateComponents(components, {
      isClientMobile,
      isClientTablet,
      isClientLaptop,
      isBlackTheme: !!blackFridayTheme?.isBlackTheme,
    });

    return (
      <HomeContainer
        {...buildTestIds("base-home-container")}
        isBlackTheme={!!blackFridayTheme?.isBlackTheme}
      >
        {pageTemplateComponents}
      </HomeContainer>
    );
  }
);

Home.displayName = "Home";

export default Home;
