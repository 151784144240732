import styled from "styled-components";
import { FlexContainer, theme } from "ui";

type HomeContainerProps = {
  isBlackTheme: boolean;
};

const HomeContainer = styled(FlexContainer)<HomeContainerProps>`
  flex-direction: column;
  /* Espaçamento de 48px alinhado com time de design, conforme o que já foi definido no preview da Home no Site Manager */
  gap: ${theme.space.x12};
  padding: ${theme.space.x0};
  background-color: ${({ isBlackTheme }) =>
    isBlackTheme
      ? theme.colors.blackFriday["200"]
      : theme.colors.neutral["25"]};
  margin: 0px auto;

  @media ${theme.device.desktopFirst.mobileXL} {
    gap: ${theme.space.x6};
  }
`;

export { HomeContainer };
