import {
  handleBackofficeContent,
  handlePreloadComponents,
  serverSideDeviceDetection,
} from "application";
import {
  appMonitoringClient,
  backofficeApi,
  cookie,
  envsApi,
} from "implementations";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { BlackFridayThemeDto, NewTemplateDto } from "typing";
import Home from "../src/screens/Home";
import { isBlackFridayServerSideProps } from "../src/utils/server-side";

export const getServerSideProps: GetServerSideProps<
  NewTemplateDto & { blackFridayTheme: BlackFridayThemeDto | null | undefined }
> = async (context) => {
  const { req, res } = context;

  const { data: isNewSeoAvailable } = envsApi.getRawEnvImmediately(
    "IS_NEW_SEO_AVAILABLE"
  );

  const branchId =
    cookie.getCookie({
      name: "@FC:Ecom:Dropdown:BranchLocation",
      options: { req, res },
    }) ?? "2";

  const response = await backofficeApi.getPageTemplate("Home", branchId);

  if (!response?.data) {
    appMonitoringClient.captureMessage(
      `A home do site não obteve nenhum dado de ${backofficeApi.getPageTemplateEndpoint(
        "Home",
        branchId,
        1
      )}`,
      {
        level: "fatal",
        tags: {
          fcx_labs_error_source: "api",
        },
      }
    );

    return {
      notFound: true,
    };
  }

  const blackFridayTheme = await isBlackFridayServerSideProps(backofficeApi);

  const { isMobile } = serverSideDeviceDetection({
    req,
  } as GetServerSidePropsContext);

  const { components, playlist } = handleBackofficeContent(
    response,
    isNewSeoAvailable === "true"
  );

  return {
    props: {
      playlist,
      components: handlePreloadComponents(
        components,
        isMobile,
        isMobile ? 4 : 3
      ),
      blackFridayTheme,
    },
    // revalidate: 30,
  };
};

export default Home;
